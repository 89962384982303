import { Box } from '@mui/material';
import OptionsFeedDatagrid from './OptionsFeedDatagrid';
import useTnSWebsocket from 'hooks/optionsFeed/useTnSWebsocket';
import { useEffect, useMemo, useState } from 'react';
import { RecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { isMarketOpenState } from 'states';
import { decode } from '@msgpack/msgpack';
import {
  DEFAULT_TNS_SYMBOLS,
  OF_DEFAULT_FILTER_ID,
  OPTIONS_FEED_MAX_ROWS,
  OptionsFeedFilterTab,
} from 'config/optionsFeed';
import { getOptionFeedDataRow, satisfyFilters } from 'util/optionsFeed';
import { fetchRawAPI } from 'util/shared';
import { useLog } from 'hooks';
import {
  Filter,
  FilterItem,
  FilterPanelProps,
  OptionsFeedColumnKey,
  OptionsFeedColumnSizes,
  RawOptionFeedData,
} from 'types/optionsFeed';
import {
  GridColumnVisibilityModel,
  GridSlotsComponentsProps,
} from '@spotgamma/x-data-grid-premium';
import { useOptionsFeedColumns } from './useOptionsFeedColumns';
import FiltersContainer from './filters/FiltersContainer';
import useEquities from 'hooks/equityhub/useEquities';
import { tnsEquityScannersDataState } from 'states/optionsFeed';
import useTnsFilters from 'hooks/optionsFeed/useTnsFilters';
import useToast from 'hooks/useToast';

interface OptionsFeedProps {
  filterPanelProps: FilterPanelProps;
  disabledColumnFilters?: OptionsFeedColumnKey[];
  disableWatchlistSelector?: boolean;
  tnsFlowLiveState: RecoilState<boolean>;
  activeCustomFilterState: RecoilState<Filter | undefined>;
  newFilterItemsState: RecoilState<FilterItem[]>;
  savedFiltersState: RecoilState<Filter[]>;
  filterActiveTabState: RecoilState<OptionsFeedFilterTab>;
  columnVisibilityState: RecoilState<GridColumnVisibilityModel>;
  columnOrderState: RecoilState<OptionsFeedColumnKey[]>;
  columnSizingState: RecoilState<OptionsFeedColumnSizes>;
  activeWatchlistIdsState: RecoilState<number[]>;
  customGridSlotProps?: GridSlotsComponentsProps;
}

const OptionsFeed = ({
  disabledColumnFilters,
  filterPanelProps,
  activeCustomFilterState,
  savedFiltersState,
  newFilterItemsState,
  columnVisibilityState,
  columnOrderState,
  columnSizingState,
  filterActiveTabState,
  tnsFlowLiveState,
  customGridSlotProps,
}: OptionsFeedProps) => {
  const { logError } = useLog('OptionsFeedPage');
  const { openToast } = useToast();
  const isTnsFlowLive = useRecoilValue(tnsFlowLiveState);
  const [eqScannersLoading, setEqScannersLoading] = useState<boolean>(false);
  const setEqScanners = useSetRecoilState(tnsEquityScannersDataState);
  const { getEquityScanners } = useEquities();
  const [histData, setHistData] = useState<RawOptionFeedData[]>([]);
  const [histError, setHistError] = useState<string | undefined>(undefined);
  const [histDataLoading, setHistDataLoading] = useState<boolean>(false);

  const activeCustomFilter = useRecoilValue(activeCustomFilterState);
  const newFilters = useRecoilValue(newFilterItemsState);

  const currentFilters = activeCustomFilter?.value ?? newFilters;

  const setSavedFilters = useSetRecoilState(savedFiltersState);

  const { fetchSavedFilters } = useTnsFilters();
  const { columns } = useOptionsFeedColumns({ disabledColumnFilters });

  const isMarketOpen = useRecoilValue(isMarketOpenState);

  const currentSyms = useMemo(() => {
    if (filterPanelProps.currentSym) {
      return [filterPanelProps.currentSym];
    }

    return (
      (currentFilters.find((f) => f.id === OF_DEFAULT_FILTER_ID.Symbols)
        ?.value as string[]) ?? DEFAULT_TNS_SYMBOLS
    );
  }, [currentFilters, filterPanelProps.currentSym]);

  // data comes through this websocket
  const { error, rows } = useTnSWebsocket(
    currentSyms,
    currentFilters,
    isTnsFlowLive,
  );

  useEffect(() => {
    async function fetchFilters() {
      try {
        const myFilters: Filter[] = await fetchSavedFilters(false); // fetch only noSym:false filters
        setSavedFilters(myFilters);
      } catch (err: any) {
        console.error(err);
        openToast({
          message: err.message,
          type: 'error',
          duration: 10000,
        });
      }
    }
    fetchFilters();
  }, []);

  useEffect(() => {
    async function fetchEquityScanners() {
      try {
        setEqScannersLoading(true);
        const sc = await getEquityScanners(); // Fetch all equity scanners data
        setEqScanners(sc);
      } catch (err) {
        console.error(err);
        openToast({
          message:
            'Something went wrong while fetching equity scanners data. Refresh the page to retry or contact us if the issue persists.',
          type: 'error',
          duration: 10000,
        });
      } finally {
        setEqScannersLoading(false);
      }
    }
    fetchEquityScanners();
  }, []);

  useEffect(() => {
    const getData = async (limit = OPTIONS_FEED_MAX_ROWS) => {
      try {
        setHistDataLoading(true);
        const response = await fetchRawAPI(
          `v2/history?sym=${encodeURIComponent(
            currentSyms.join(','),
          )}&limit=${limit}&stream=absolute`,
          {},
          'https://dev.stream.spotgamma.com', // temporarily hardcoding host for fetching
        );

        const arrayBuffer = await response.arrayBuffer();
        const histData: any = decode(arrayBuffer);

        const cleanData = [
          ...histData
            .map((d: any[]) => getOptionFeedDataRow(d))
            .slice(histData.length - OPTIONS_FEED_MAX_ROWS, histData.length),
        ];

        setHistData((prev) =>
          [...cleanData, ...prev]
            .slice(0, OPTIONS_FEED_MAX_ROWS)
            .filter((d) => satisfyFilters(d, currentFilters)),
        );
      } catch (err) {
        console.error(err);
        logError(err);
        setHistError('Something went wrong while fetching data...');
      } finally {
        setHistDataLoading(false);
      }
    };

    if (!isMarketOpen) {
      getData(OPTIONS_FEED_MAX_ROWS);
    } else {
      // get quarter of data just to populate using most recent data
      getData(OPTIONS_FEED_MAX_ROWS / 4);
    }
  }, [isMarketOpen, currentFilters, isTnsFlowLive]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflowY: 'hidden',
        display: 'flex',
        gap: '8px',
      }}
    >
      <FiltersContainer
        activeCustomFilterState={activeCustomFilterState}
        activeTabState={filterActiveTabState}
        newFilterItemsState={newFilterItemsState}
        savedFiltersState={savedFiltersState}
        filterPanelProps={filterPanelProps}
      />

      <OptionsFeedDatagrid
        rows={[...rows, ...histData]}
        error={error || histError}
        columns={columns}
        filterPanelOpenState={filterPanelProps.openState}
        flowLiveState={tnsFlowLiveState}
        columnVisibilityState={columnVisibilityState}
        columnOrderState={columnOrderState}
        columnSizingState={columnSizingState}
        customGridSlotProps={customGridSlotProps}
        loading={histDataLoading || eqScannersLoading}
      />
    </Box>
  );
};

export default OptionsFeed;
