import {
  alpha,
  Box,
  Button,
  Portal,
  Stack,
  SxProps,
  Theme,
  useTheme,
} from '@mui/material';
import {
  DataGridPremium,
  GridColDef,
  GridColumnOrderChangeParams,
  GridColumnResizeParams,
  GridColumnVisibilityModel,
  GridFilterAltIcon,
  GridLogicOperator,
  GridSlots,
  GridSlotsComponentsProps,
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from '@spotgamma/x-data-grid-premium';
import { useMemo } from 'react';
import {
  OptionsFeedColumnKey,
  OptionsFeedColumnSizes,
  RawOptionFeedData,
} from 'types/optionsFeed';

import {
  defaultGridTableSlots,
  getDefaultGridTableSlotProps,
  getDefaultGridTableStyles,
} from 'util/shared';
import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';
import PauseCircleFilledRoundedIcon from '@mui/icons-material/PauseCircleFilledRounded';
import { RecoilState, useRecoilState, useRecoilValue } from 'recoil';
import { isMobileState, userDetailsState } from 'states';
import {
  DEFAULT_TNS_FIELDS,
  TNS_UNMODIFIABLE_FIELDS,
} from 'config/optionsFeed';
import GridTableOverlayWrapper from './GridTableOverlayWrapper';
import DataAgreementContainer from './DataAgreementContainer';
import { CustomGridController } from 'types';

interface CustomToolbarProps {
  customPositionedControllers?: CustomGridController[];
  disableToolbar?: boolean;
  filterPanelOpenState: RecoilState<boolean>;
  flowLiveState: RecoilState<boolean>;
}

const CustomToolbar = ({
  customPositionedControllers,
  filterPanelOpenState,
  disableToolbar,
  flowLiveState,
}: CustomToolbarProps) => {
  const theme = useTheme();
  const sxProps: SxProps<Theme> = {
    textTransform: 'capitalize',
    fontSize: {
      xs: 12,
      md: 14,
    },
  };

  const [filterPanelOpen, setFilterPanelOpen] =
    useRecoilState(filterPanelOpenState);

  const [flowLive, setFlowLive] = useRecoilState(flowLiveState);

  return (
    <>
      {customPositionedControllers?.map((c) => (
        <Portal container={() => document.getElementById(c.elementId)!}>
          <Box key={c.elementId} sx={{ marginX: '4px' }}>
            {c.component}
          </Box>
        </Portal>
      ))}
      {!disableToolbar && (
        <GridToolbarContainer
          sx={{ padding: '5px', backgroundColor: 'transparent' }}
        >
          <Stack alignItems="center" gap="8px" direction="row">
            <Button
              variant="outlined"
              size="small"
              startIcon={<GridFilterAltIcon />}
              sx={{
                ...sxProps,
                backgroundColor: filterPanelOpen
                  ? theme.palette.button.default
                  : 'inherit',
                ':hover': {
                  backgroundColor: filterPanelOpen
                    ? theme.palette.button.hover
                    : 'inherit',
                },
              }}
              onClick={() => setFilterPanelOpen((prev) => !prev)}
            >
              Filters
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{
                ...sxProps,
              }}
              startIcon={
                flowLive ? (
                  <PauseCircleFilledRoundedIcon />
                ) : (
                  <PlayCircleFilledRoundedIcon />
                )
              }
              onClick={() => setFlowLive((prev) => !prev)}
            >
              {flowLive ? 'Live Flow' : 'Paused Flow'}
            </Button>
            <GridToolbarColumnsButton
              slotProps={{
                button: {
                  sx: sxProps,
                },
              }}
            />
          </Stack>
        </GridToolbarContainer>
      )}
    </>
  );
};

interface Props {
  rows: RawOptionFeedData[];
  columns: GridColDef[];
  flowLiveState: RecoilState<boolean>;
  filterPanelOpenState: RecoilState<boolean>;
  columnVisibilityState: RecoilState<GridColumnVisibilityModel>;
  columnOrderState: RecoilState<OptionsFeedColumnKey[]>;
  columnSizingState: RecoilState<OptionsFeedColumnSizes>;
  error?: string;
  customGridSlotProps?: GridSlotsComponentsProps;
  loading?: boolean;
}

const OptionsFeedDatagrid = ({
  rows,
  error,
  columns,
  loading,
  filterPanelOpenState,
  flowLiveState,
  columnVisibilityState,
  columnOrderState,
  columnSizingState,
  customGridSlotProps,
}: Props) => {
  const isMobile = useRecoilValue(isMobileState);
  const userDetails = useRecoilValue(userDetailsState);
  const theme = useTheme();
  const [columnVisibilityModel, setColumnVisibilityModel] = useRecoilState(
    columnVisibilityState,
  );
  const [columnOrder, setColumnOrder] = useRecoilState(columnOrderState);
  const [columnSizing, setColumnSizing] = useRecoilState(columnSizingState);

  const getTogglableColumns = (columns: GridColDef[]) => {
    return columns
      .filter(
        (column) =>
          !TNS_UNMODIFIABLE_FIELDS.includes(
            column.field as OptionsFeedColumnKey,
          ),
      )
      .map((column) => column.field);
  };

  const orderedColumns = useMemo(() => {
    const orderMap = new Map(columnOrder.map((field, index) => [field, index]));
    const fallback = new Map(
      DEFAULT_TNS_FIELDS.map((field, idx) => [field, idx + columns.length]),
    );

    return [...columns].sort(
      (a, b) =>
        (orderMap.get(a.field as OptionsFeedColumnKey) ??
          fallback.get(a.field as OptionsFeedColumnKey)!) -
        (orderMap.get(b.field as OptionsFeedColumnKey) ??
          fallback.get(b.field as OptionsFeedColumnKey)!),
    );
  }, [columnOrder, columns]);

  const dynamicallySizedColumns = useMemo(() => {
    return orderedColumns.map((col: GridColDef) => ({
      ...col,
      width: columnSizing[col.field as OptionsFeedColumnKey],
    }));
  }, [orderedColumns, columnSizing]);

  const maxWidth = useMemo(
    () =>
      dynamicallySizedColumns.reduce(
        (acc, col) => acc + Math.max(col.width ?? 0, col.minWidth ?? 0),
        0,
      ),
    [dynamicallySizedColumns],
  );

  const handleColumnOrderChange = (
    params: GridColumnOrderChangeParams,
    _event: any,
    _details: any,
  ) => {
    const result = columnOrder.slice(); // Create a copy of the array
    const [removedElement] = result.splice(params.oldIndex, 1); // Remove the element from its original position
    result.splice(params.targetIndex, 0, removedElement); // Insert the element at the new position

    setColumnOrder(result);
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.paper,
        padding: '24px',
        borderRadius: '8px',
        display: 'flex',
        overflowX: 'hidden',
        flexGrow: 1,
        maxWidth: '100%',
      }}
    >
      {userDetails?.isInstitutional === false ? (
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            maxWidth: isMobile ? maxWidth : maxWidth + 10,
            height: '100%',
            overflowY: 'hidden',
          }}
        >
          <DataGridPremium
            loading={loading}
            rows={rows}
            // update datagrid rows ui every 3s
            throttleRowsMs={3000}
            filterDebounceMs={300}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => {
              setColumnVisibilityModel(newModel);
            }}
            onColumnOrderChange={handleColumnOrderChange}
            onColumnResize={(params: GridColumnResizeParams) =>
              setColumnSizing((prev) => ({
                ...prev,
                [params.colDef.field]: params.width,
              }))
            }
            columns={dynamicallySizedColumns}
            initialState={{
              pinnedColumns: { left: [] },
              sorting: {
                sortModel: [
                  {
                    field: OptionsFeedColumnKey.Time,
                    sort: 'desc', // latest time always at the top by default
                  },
                ],
              },
              // TODO: figure out default filters that are reasonable on initial load
              // filter: {
              //   filterModel: {
              //     items: [
              //       {
              //         field: OptionsFeedColumnKey.Premium,
              //         operator: '>',
              //         value: 500,
              //       },
              //     ],
              //   },
              // },
            }}
            hideFooter
            disableRowSelectionOnClick
            disableMultipleRowSelection
            disableAggregation
            rowHeight={45}
            columnHeaderHeight={60}
            disableRowGrouping
            density="compact"
            slots={{
              ...defaultGridTableSlots,
              toolbar: CustomToolbar as GridSlots['toolbar'],
              noRowsOverlay:
                GridTableOverlayWrapper as GridSlots['noRowsOverlay'],
            }}
            slotProps={{
              ...getDefaultGridTableSlotProps(theme),
              toolbar: {
                filterPanelOpenState,
                flowLiveState,
              },
              filterPanel: {
                ...getDefaultGridTableSlotProps(theme).filterPanel,
                logicOperators: [GridLogicOperator.And],
              },
              columnsManagement: {
                getTogglableColumns,
              },
              noRowsOverlay: {
                error,
                loading,
              } as any,
              ...customGridSlotProps,
            }}
            sx={{
              ...getDefaultGridTableStyles(theme),
              backgroundColor: 'trasparent',
              '& .MuiDataGrid-row': {
                backgroundColor: theme.palette.background.default,
              },
              '& .MuiDataGrid-scrollbarFiller': {
                background: theme.palette.background.paper,
              },
              '& .grid-header-cell': {
                backgroundColor: theme.palette.background.paper,
                ':hover': {
                  backgroundColor: alpha(theme.palette.text.primary, 0.1),
                },
                border: 'none',
              },
            }}
          />
        </Box>
      ) : (
        <Stack
          gap={2}
          sx={{
            width: '100%',
            height: '70vh',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <DataAgreementContainer />
        </Stack>
      )}
    </Box>
  );
};

export default OptionsFeedDatagrid;
